ranking-game-table {
  @import "../../../styles/styles";

  .display-none {
    display: none !important;
  }

  .player-rank, .player-trophy {
    width: multiply-size(50px);
    height: multiply-size(25px);

    img {
      width: multiply-size(50px);
      height: multiply-size(25px);
      object-fit: contain;
    }
  }
}
