@import "~src/styles/functions";

app-custom-login {
  @import "../../../../basic/styles/styles";

  #{$context-mobile} & {
    --multiply: 1.5;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  #{$context-desktop} & {
    --multiply: 1.5;
  }

  .logo-budimex {
    min-width: 234px;
    width: 234px;
    min-height: 63px;
    height: 63px;
    background: var(--asset--ui-budimex_princ_pos_cmyk-png) no-repeat;
    background-size: contain;
    margin-bottom: 20px;
  }

  .background-img {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;

    }
  }

  button {
    &.login {
      margin-top: 45px;
      margin-bottom: 10px
    }
  }
}

