@import "~src/styles/variables/core/variables";
@import "~src/styles/functions";
@import "~src/styles/mixins";

app-hud-custom {
  .top-left-container {
    z-index: 1;
    position: absolute;
    top: multiply-size(12px);
    left: multiply-size(12px);

    .avatar-background {
      width: multiply-size(98px);
      height: multiply-size(102px);
      background: var(--asset--ui-main_user-png) no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .avatar-inner-background {
        width: multiply-size(70px);
        height: multiply-size(70px);
        border: multiply-size(3px) solid #000000;
        border-radius: 50%;
        box-sizing: border-box;
        margin-bottom: multiply-size(6px);
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .rank-name {
      position: absolute;
      z-index: -1;
      left: multiply-size(49px);
      top: multiply-size(6px);
      padding: multiply-size(2px) multiply-size(10px) multiply-size(1px) multiply-size(55px);
      background: #ffffff;
      border: multiply-size(3px) solid #000000;
      font-size: multiply-size(20px);
      @include mikado;
      color: #000000;
      white-space: nowrap;
    }
  }

  .top-center-container {
    top: multiply-size(22px);
    @include absolute-center-horizontal;
    @include mikado;
    font-size: multiply-size(20px);
    color: #000000;
    display: flex;

    & > * {
      margin: 0 multiply-size(10px);
    }

    .item-box-container {
      border: multiply-size(3px) solid #000000;
      background: $color-X;
      padding: multiply-size(2px) multiply-size(10px) multiply-size(1px) multiply-size(48px);
      position: relative;

      .icon {
        width: multiply-size(50px);
        height: multiply-size(50px);
        background-size: contain;
        position: absolute;
        @include absolute-center-vertical;
        left: multiply-size(-13px);
      }

      &.timer {
        min-width: multiply-size(55px);
        text-align: center;

        .icon {
          background-image: var(--asset--icons-hud-time-png);
        }
      }

      &.currency {
        margin-left: multiply-size(13px);

        .icon {
          background-image: var(--asset--currency-hud-points-png);
        }
      }
    }
  }
}

