@import "~src/styles/variables/core/variables";
@import "~src/styles/functions";
@import "~src/styles/mixins";

app-quiz-slider {
  @import "../styles/quiz-window";

  .global-dialog {
    display: flex;
    align-items: center;
    justify-content: center;

    .image-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: multiply-size(430px);
      height: multiply-size(255px);

      img {
        object-fit: contain;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;

        &.active {
          z-index: 1;
        }
      }
    }

    .number-slide {
      @include absolute-center-horizontal;
      bottom: 0;
      @include mikado;
      font-weight: bold;
      font-size: multiply-size(8px);
      background: #ffffff;
      border: multiply-size(2px) solid #000000;
      padding: multiply-size(1px) multiply-size(5px);
      transform: translate(-50%, 100%);
    }
  }
}
