@import "~src/styles/functions";

// no-multiply-size-file
app-oauth-confirm {
  @import "../../../../basic/styles/styles";

  #{$context-mobile} & {
    --multiply: 1.5;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  #{$context-desktop} & {
    --multiply: 1.5;
  }

  form {
    height: 100%;
  }

  .control {
    &.control-checkbox {
      .custom-checkbox {
        height: 20px;
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        border-radius: 6px;
        border-width: 2px;
        box-sizing: content-box;

        .custom-checkbox-fill {
          font-size: 12px;
        }
      }

      label {
        padding: 2px 0 0 10px;
        font-weight: initial;
        font-size: 16px;
      }
    }
  }

  .avatars {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    margin: 20px 0;

    .avatar {
      width: 100px;
      height: 100px;
      border: 2px solid #8B8B8B;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 50%;
      background-size: cover;

      &.active {
        border: 4px solid #000000;
      }
    }
  }
}
