app-rrconfig-editor {
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  min-height: 100%;
  background: #fff3;

  .info {
    position: fixed;
    left: 400px;
    top: 10px;
  }

  /* Animate items as they’re being sorted. */
  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* Animate an item that has been dropped. */
  .cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }

}
