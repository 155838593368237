@import "~src/styles/functions";
perfect-scrollbar {
  &.scroll-indicator {
    &:after {
      position: absolute;
      width: multiply-size(6px, '100% - ');
      bottom: 0;
      left: 0;
      height: multiply-size(22px);
      content: ' ';
      z-index: 2;
      pointer-events: none;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.84) 100%);
      box-shadow: 0 multiply-size(-5px) multiply-size(10px) 0 #ffffff;
    }
  }

  .ps__rail-y {
    width: multiply-size(3px) !important;
    background: transparent !important;

    .ps__thumb-y {
      background: rgba(200, 200, 200) !important;
      width: multiply-size(3px) !important;
      right: 0;
      cursor: pointer;
    }

    &:hover {
      .ps__thumb-y {
        width: multiply-size(1px);
        background: rgb(180, 180, 180) !important;
      }
    }
  }
}
