@import "~src/styles/functions";
@import "~src/styles/mixins";

app-core-warehouse {
  @import "variables";


  .window-a {
    padding: multiply-size(30px) multiply-size(20px) multiply-size(12px) multiply-size(26px);
  }

  .categories {
    margin-top: multiply-size(9px);
    display: flex;
    align-items: center;
    justify-content: center;

    .category {
      margin: 0 multiply-size(10px);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-transform: uppercase;
        @include myriad-cond;
        font-weight: bold;
        cursor: pointer;
        font-size: multiply-size(14px);

        &.active {
          color: $color-I;
          @include text-stroke();
        }

        &.new-products {
          color: $color-B;
          @include text-stroke();
        }
      }
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: multiply-size(10px);

    .product-in-glow-box-big {
      width: multiply-size(76px);
      height: multiply-size(75px);
      margin: multiply-size(12px) 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .clip-path-big {
        position: absolute;
        top: multiply-size(-3px);
        left: multiply-size(-3px);
        height: 100%;
        width: 100%;
        border: multiply-size(3px) solid $color-K;
        border-radius: multiply-size(23px);
        clip-path: polygon(16.69% 81.88%, 19.74% 110.29%, -1.83% 107.61%, -1.83% 0%, 19.82% -18.52%, 14.74% 30.41%, 84.7% 30.4%, 80.23% -19.70%, 102.03% 0%, 102.84% 106.79%, 78.56% 110.28%, 83.43% 82.49%);
      }

      .product-name {
        position: absolute;
        top: multiply-size(-3px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: multiply-size(6px);
        padding: 0 multiply-size(12px);
        box-sizing: border-box;
        font-size: multiply-size(10px);
        @include myriad-cond;
        font-weight: bold;
        left: 0;
      }

      .product-icon {
        width: multiply-size(38px);
        height: multiply-size(38px);
        object-fit: contain;
      }

      .product-balance {
        position: absolute;
        bottom: multiply-size(-8px);
        font-size: multiply-size(10px);
        @include myriad-cond;
        font-weight: bold;
        left: 0;
        text-align: center;
        width: 100%;
      }

      &:nth-child(5n+5) {
        margin-right: multiply-size(3px);
      }

      &:nth-child(5n+1) {
        margin-left: multiply-size(3px);
      }

      &:hover {
        color: $color-I;
        cursor: pointer;

        .product-name, .product-balance {
          @include text-stroke;
        }

        .clip-path-big {
          border: multiply-size(3px) solid $color-I;
        }
      }

      &.empty-placeholder {
        pointer-events: none;
      }
    }
  }

  @import "custom/style";
}
