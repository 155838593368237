@import "~src/styles/functions";
@import "~src/styles/mixins";

app-current-step-quiz-game {
  .button-start {
    width: multiply-size(174px);
    height: multiply-size(36px);
    font-size: multiply-size(20px);
    @include mikado;

    .is-loading &.loading-opacity {
      opacity: 1;
      filter: none;
      pointer-events: none;

      span {
        display: none;
      }
    }
  }

  .button-counter {
    border-radius: 50%;
    width: multiply-size(49px);
    min-width: multiply-size(49px);
    height: multiply-size(51px);
    padding: 0;
    @include mikado;
    font-size: multiply-size(20px);
    pointer-events: none;
  }
}
