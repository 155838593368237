@import "../../core/variables";
@import "../mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #A2CD3D,
    $active: #7EA030,
  ),
  $box-shadow: (
    $normal: #4C6D20,
    $active: #4C6D20,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
  )
);

&.secondary {
  @include generate-schema-button-color-custom($colors);

  &, i {
    text-shadow: initial;
  }
}
