app-minigame-river-ride {
  .rr-game-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);

    #minigame-river-ride {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  app-rrconfig-editor {
    //position: fixed;
    //left: 0;
    //top: 0;
    //width: 400px;
    //height: 10vh;
  }
}

