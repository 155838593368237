@import "~src/styles/functions";
@import "../core/variables";

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.6) !important;
  }
}

.ow-dialog {
  &.window-a {
    &.primary {
      .back-bar {
        left: multiply-size(-20px);
      }

      .bottom-bar {
        bottom: multiply-size(-26px);
      }

      .menu-right {
        right: multiply-size(-38px);
      }

      .menu-left {
        left: multiply-size(-38px);
      }

      .navigation-bar {
        &.prev {
          left: multiply-size(-40px);
        }

        &.next {
          right: multiply-size(-40px);
        }
      }
    }
  }

  &.window-a, &.window-b, &.window-c, &.window-d {
    &.primary {
      --border-radius: 0px; // no-multiply-size

      .navigation-bar {
        &.prev {
          &:before {
            border-radius: 0 0 0 multiply-size(var(--border-radius));
          }
        }

        &.next {
          &:before {
            border-radius: 0 multiply-size(var(--border-radius)) multiply-size(var(--border-radius)) 0;
          }
        }
      }

      .right-overlap {
        &:before {
          border-radius: 0 multiply-size(var(--border-radius)) multiply-size(var(--border-radius)) 0;
        }
      }

      .left-overlap, .back-bar {
        &:before {
          border-radius: multiply-size(var(--border-radius)) 0 0 multiply-size(var(--border-radius));
        }
      }

      .bottom-overlap {
        &:before {
          border-radius: 0 0 multiply-size(var(--border-radius)) multiply-size(var(--border-radius));
        }
      }

      .bottom-bar {
        .bar-button {
          &:before {
            border-radius: 0 0 multiply-size(var(--border-radius)) multiply-size(var(--border-radius));
          }
        }
      }
    }
  }
}

.global-dialog {
  color: #000000;
  line-height: 1.1;
}
