@import "~src/styles/functions";

app-login {
  @import "../../styles/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }
  }


  .control {
    &.control-checkbox {
      &.remember-me-container {
        margin-top: multiply-size(20px);
        align-items: center;

        label {
          padding: 0;
          padding-left: multiply-size(8px);
        }
      }
    }
  }
}
