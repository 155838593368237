@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

app-ranking-list {
  .window-a {
    padding: multiply-size(14px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      td {
        padding: 0 multiply-size(9px);
        @include myriad-cond;
        font-size: multiply-size(12px);
        text-align: left;
        height: multiply-size(36px);
        border-bottom: multiply-size(1px) solid black;

        .status-rank {
          @include text-stroke;
          @include mikado;
          font-size: multiply-size(9px);

          &.is-active {
            color: $color-B;
          }

          &.is-cleared {
            color: #ff001d;
          }
        }

        &:first-child {
          padding-left: multiply-size(10px);
          font-weight: initial;
        }

        &:last-child {
          padding-right: multiply-size(10px);
        }

        .buttons-container {
          display: flex;
          justify-content: flex-end;

          button {
            &:not(:last-child) {
              margin-right: multiply-size(12px);
            }

            i {
              font-size: multiply-size(10px);
            }
          }
        }
      }
    }
  }

  .categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: multiply-size(8px);
    border-bottom: multiply-size(1px) solid black;
    margin-bottom: multiply-size(2px);

    .category {
      padding: 0 multiply-size(10px);
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        border-right: multiply-size(1px) solid #000000;
      }

      span {
        text-transform: uppercase;
        @include myriad-cond;
        font-weight: bold;
        cursor: pointer;
        font-size: multiply-size(12px);

        &.active {
          color: $color-I;
          @include text-stroke();
        }
      }
    }
  }

  .ow-dialog {
    .bottom-bar {
      .pagination {
        .pages {
          width: multiply-size(20px);
          text-align: center;
        }

        button {
          i {
            font-size: multiply-size(10px);
          }
        }
      }
    }
  }
}
