app-minigame-summary {
  .ow-dialog {
    //display: flex;
  }

  .results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .summary {
      position: relative;
      padding: multiply-size(10px);
      width: multiply-size(150px);
      height: multiply-size(200px);
      background: #fff;
      border: multiply-size(1.5px) solid #000000;

      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;

      font-size: multiply-size(11px);
      font-weight: bold;
      @include mikado;

      .result {
        display: flex;
        width: multiply-size(87.5px);
        align-items: center;

        &:first-child {
          margin-top: multiply-size(10px);
        }
      }

      button {
        position: absolute;
        bottom: multiply-size(10px);
        width: multiply-size(70px);
      }
    }
  }
}

.separator {
  width: multiply-size(100px);
  height: multiply-size(1px);
  background: #000;
  margin-top: multiply-size(12.5px);
  margin-bottom: multiply-size(5px);
}
