@import "~src/styles/functions";

&.square {
  border: multiply-size(2px) solid;

  &.x-small {
    width: multiply-size(19px);
    height: multiply-size(20px);

    i {
      font-size: multiply-size(8px);
    }
  }

  &.small {
    border-radius: multiply-size(0px);
  }

  &.normal {
    border-radius: multiply-size(0px);
  }
}
