@import "../../core/variables";
@import "../mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #FFCB03,
    $active: #FFB300,
  ),
  $box-shadow: (
    $normal: #D47500,
    $active: #D47500,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
  )
);

&.primary {
  @include generate-schema-button-color-custom($colors);

  &, i {
    text-shadow: initial;
  }
}
