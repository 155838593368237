@import "~src/styles/functions";
@import "~src/styles/mixins";

app-message-details-comics-custom {
  .global-dialog.ow-dialog.window-a.primary {
    --window-width:  430px;
    --window-height:  255px;
    padding: multiply-size(10px);
    background: #ffffff;
    border: multiply-size(2px) solid #000000;
    box-sizing: content-box;

    .height-100, .gallery-page {
      height: 100%;
      width: 100%;
      img{
        height: 100%;
        width: 100%;
      }
    }

    .navigation-bar {
      &.next, &.prev {
        &:before {
          content: initial;
        }
      }
    }

    m-ui-close-button {
      .exit-bar {
        top: multiply-size(-7px);
        right: multiply-size(-30px);
        transform: translate(-1px, -1px);
      }
    }
  }

  .number-slide {
    @include absolute-center-horizontal;
    bottom: 0;
    @include mikado;
    font-weight: bold;
    font-size: multiply-size(8px);
    background: #ffffff;
    border: multiply-size(2px) solid #000000;
    padding: multiply-size(1px) multiply-size(5px);
    transform: translate(-50%, 100%);
  }
}
