@import "~src/styles/variables/core/variables";
@import "~src/styles/functions";
@import "~src/styles/mixins";

app-quiz-bonus {
  @import "../styles/quiz-window";

  .global-dialog {
    padding: multiply-size(4px) multiply-size(0);
    width: calc(450px* var(--multiply));
    height: calc(258.5px* var(--multiply));
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;
    outline: multiply-size(2px) solid #000000;;
  }

  .info {
    @include archivo-narrow;
    font-size: multiply-size(16px);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    @include archivo-narrow;
    font-size: multiply-size(16px);
    height: multiply-size(250.5px);
    width: multiply-size(450px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .quiz-answer{
      position: absolute;
      background-color: transparent;
      cursor: pointer;
    }
    .show-checked::after {
      content: '';
      display: block;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 66px; /* Image width */
      height: 66px; /* Image height */
      background-image: var(--asset--ui-pin-png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
