@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";

&.window-a {
  --window-width: 478px; // no-multiply-size;
  --window-height: 280px; // no-multiply-size;

  width: multiply-size(var(--window-width));
  height: multiply-size(var(--window-height));
  border-radius: 0;
  box-shadow: 0 0 0 0 $color-H;
  z-index: 0;
}
