app-summary {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--asset--ui-backgroud_log-png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .summary-details {
    .exit {
      position: absolute;
      top: -25px;
      right: -20px;

      i {
        text-shadow: none;
        color: #000000;
      }
    }
  }

  .logout-button, .support {
    position: absolute;
    right: 20px;
  }

  .logout-button {
    top: 20px;
    font-size: 14px;
  }

  .support {
    @include archivo-narrow();
    font-size: 20px;
    letter-spacing: 0;
    bottom: 20px;

    a {
      text-decoration: none;
      color: #000000;
    }
  }

  .container {
    --multiply: 1.5;
    margin-top: 5px;
    background: #FFFFFF;
    padding: 30px 35px 0 35px;
    border: 3px solid #000000;
    font-size: 16px;
    @include archivo-narrow;
    display: flex;
    flex-direction: column;
    width: 400px;
    box-sizing: border-box;

    &.summary-details {
      width: 620px;
    }

    .display-grid {
      display: grid;
    }

    .description {
      font-size: 16px;
      font-weight: initial;
      margin-bottom: 14px;

      &.text-bold {
        font-weight: bold;
      }
    }

    button {
      margin: 10px 0;
      font-size: 20px !important;
    }

    .timer {
      font-size: 20px;
      @include mikado;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: -5px;
    }

    .vehicle {
      width: 300px;
      object-fit: contain;
      margin: 10px auto -50px auto;
    }
  }

  .user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-background {
      width: multiply-size(98px);
      height: multiply-size(102px);
      background: var(--asset--ui-main_user-png) no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .avatar-inner-background {
        width: multiply-size(70px);
        height: multiply-size(70px);
        border: multiply-size(3px) solid #000000;
        border-radius: 50%;
        box-sizing: border-box;
        margin-bottom: multiply-size(6px);
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .rank-name {
      display: inline-block;
      padding: multiply-size(2px) multiply-size(33px) multiply-size(1px) multiply-size(33px);
      background: #ffffff;
      border: multiply-size(3px) solid #000000;
      font-size: multiply-size(20px);
      @include mikado;
      color: #000000;
      white-space: nowrap;
      text-align: center;
      transform: translateY(multiply-size(-12px));
    }
  }
}
