@import "~src/styles/functions";
@import "~src/styles/mixins";
@import "~src/styles/variables/core/variables";

.global-dialog {
  width: multiply-size(450px);
  height: multiply-size(275px);

  border: multiply-size(2px) solid #000000;
  box-sizing: border-box;
  background: #ffffff;
  padding: multiply-size(30px) multiply-size(25px) multiply-size(8px) multiply-size(25px);

  m-ui-close-button {
    .exit-bar {
      width: auto;
      height: auto;
      top: 0;
      right: 0;

      button {
        padding: multiply-size(8px) multiply-size(5px);
        width: auto;
        height: auto;

        i {
          color: #000000;
          @include text-stroke(transparent);
          font-size: multiply-size(10px);
        }
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    height: multiply-size(4px);
    background: $color-X;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  .rank-title {
    @include mikado;
    font-size: multiply-size(10px);
    font-weight: bold;
    padding: multiply-size(4px) multiply-size(17px);
    @include absolute-center-horizontal;
    top: 0;
    background: $color-X;
  }
}

table {
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  tr {
    th,
    td {
      padding: multiply-size(2px) multiply-size(5px);
      text-align: left;
      height: multiply-size(15px);

      &:first-child {
        padding-left: multiply-size(28px);
      }

      &.column-prizes {
        width: multiply-size(50px);
        min-width: multiply-size(50px);
      }
    }
  }

  thead {
    th {
      @include archivo-narrow;
      font-size: multiply-size(6px);
      font-weight: normal;
    }
  }

  tbody {
    td {
      @include archivo-narrow;
      font-size: multiply-size(8px);
      font-weight: bold;
      &.column-from-api{
        min-width: multiply-size(30px);
      }
    }

    tr {
      &:nth-child(odd) {
        background: var(--asset--ui-line-png) center no-repeat;
        background-size: cover;
      }
    }
  }

  .hide-element {
    visibility: collapse;

    td,
    th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      line-height: 0 !important;
      height: 0 !important;
      border: none !important;
      overflow: hidden !important;

      .prizes,
      .avatar,
      .fullname,
      .player-rank {
        height: 0 !important;
        overflow: hidden !important;
        border: none !important;
      }
    }
  }
}

.my-position {
  table {
    tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.prizes {
  display: flex;
  justify-content: flex-end;

  & > * {
    margin: 0 multiply-size(3px);
  }

  .value {
    @include archivo-narrow("!important");
    font-size: multiply-size(8px) !important;
    font-weight: bold;
  }
}

.fullname {
  width: 100%;
}

.avatar {
  box-sizing: border-box;
  border: 1px solid #707070;
  width: multiply-size(25px);
  height: multiply-size(25px);
  border-radius: 50%;
  background-size: contain;
}

.rank-details-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.no-items {
  height: 100%;
  font-size: multiply-size(12px);
  font-weight: bold;
  @include archivo-narrow;
}

perfect-scrollbar {
  &.height-auto {
    height: auto;
  }
  border-bottom: solid 2px black;
}
.header, .my-position-container {
  perfect-scrollbar {
    border-bottom: unset;
  }
}

perfect-scrollbar {
  width: 100% !important;
}

.my-position-container {
  td {
    border-bottom: none !important;
  }
}

.my-position-container {
  margin: multiply-size(5px) 0;

  label {
    @include myriad;
    font-size: multiply-size(8px);
    padding-left: multiply-size(8px);
    margin-bottom: multiply-size(3px);
    display: inline-block;
    padding-left: multiply-size(26px);
  }

  .my-position {
    padding: multiply-size(1px) 0;

    tr {
      background: none !important;
    }
  }

  perfect-scrollbar {
    color: $color-H;
  }
}

perfect-scrollbar {
  .ps-content {
    padding-right: multiply-size(10px);
  }
}
