@import "~src/styles/mixins";

app-minigame-river-ride-gui {
  .global-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &.ow-dialog {
      m-ui-close-button {
        .exit-bar {
          top: 0 !important;
          z-index: 10;
        }

        button {
          i {
            @include text-stroke();
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .minigame-container {
    width: multiply-size(500px);
    height: multiply-size(285px);
    position: relative;
    border: solid multiply-size(2px) $color-K;

    #{$context-mobile} &, #{$context-tablet} & {
      width: 100vw;
      height: 100vh;
      border: none;
    }

    .game-container, .background-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .background-container {
      background-size: cover;
      background-position: bottom;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }


    .bottom-menu {
      width: 850px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .back {
        @include absolute-center-vertical;
        left: 0;
      }

      &, button {
        color: #FFFFFF;
        font-size: 18px;
        @include myriad;
        font-weight: bold;
      }

      button {
        margin: 0 12px;
        text-transform: initial;
      }
    }
  }

  .results-container {
    .ow-dialog {
      &.window-custom-minigame {
        padding: multiply-size(82px) multiply-size(17px) multiply-size(6px) !important;
      }
    }
  }

  .results-container, .pause-container, .load-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    .ow-dialog {
      &.window-custom-minigame {
        width: multiply-size(174px);
        height: multiply-size(228px);
        border-radius: multiply-size(10px);
        border: multiply-size(2px) solid #000000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-sizing: border-box;
        padding: multiply-size(82px) multiply-size(17px) multiply-size(21px);

        .logo {
          width: multiply-size(100px);
          height: multiply-size(100px);
          background-image: var(--asset--ui-truck_ride_logo-png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          @include absolute-center-horizontal;
          top: multiply-size(-25px);
        }
      }
    }

    .title, .summary {
      @include myriad;
      font-weight: bold;
      text-transform: initial;
    }

    .title {
      font-size: multiply-size(13px);
      margin: multiply-size(25px) 0;
    }

    .summary {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .result {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:first-child {
          margin-bottom: multiply-size(4px);
        }

        .icon {
          width: multiply-size(30px);
          height: multiply-size(25px);
          box-sizing: border-box;
          margin-right: multiply-size(6px);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .sum {
          @include myriad();
          font-weight: bold;
          font-size: multiply-size(12px);

        }
      }
    }

    .separator {
      width: 100%;
      height: multiply-size(1px);
      background-color: #000000;
      margin: multiply-size(8px) 0;
    }

    .total {
      display: flex;
      align-items: center;
      justify-content: center;
      @include myriad();
      font-weight: bold;
      font-size: multiply-size(12px);
      margin-bottom: multiply-size(13px);
    }
  }

  .pause-container, .load-container {
    .ow-dialog {
      &.window-custom-minigame {
        padding: 0;
        height: 300px;
        border-radius: 0;

        .title {
          font-size: 20px;
        }
      }
      &.window-a {
        justify-content: space-evenly;
      }
    }
  }
}
