@import "~src/styles/functions";
@import "variables";

//  BASE :BEFORE OVERLAP
@mixin base-before($colors) {
  $background-color: map-get($colors, $background);
  $box-shadow-color: map-get($colors, $box-shadow);

  padding: multiply-size(7px);

  &:before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    background-color: $background-color;
    border: multiply-size(2px) solid $box-shadow-color;
    z-index: -1;
  }
}

//  TOP OVERLAP
@mixin top-overlap($colors) {
  $box-shadow-color: map-get($colors, $background);
  @include base-before($colors);

  &:before {
    top: multiply-size(1px);
    left: 0;
    width: 100%;
    height: multiply-size(21px);
    border-radius: multiply-size(14px) multiply-size(14px) 0 0;
    border-bottom-style: none;
    box-shadow: 0 multiply-size(2px) 0 0 $box-shadow-color;
  }
}

//  RIGHT OVERLAP
@mixin right-overlap($colors) {
  $box-shadow-color: map-get($colors, $background);
  @include base-before($colors);

  &:before {
    top: 0;
    right: multiply-size(1px);
    width: multiply-size(21px);
    height: 100%;
    box-shadow: multiply-size(-2px) 0 0 0 $box-shadow-color;
    border-radius: 0 multiply-size(14px) multiply-size(14px) 0;
    border-left-style: none;
  }
}

//  BOTTOM OVERLAP
@mixin bottom-overlap($colors) {
  $box-shadow-color: map-get($colors, $background);
  @include base-before($colors);

  &:before {
    bottom: multiply-size(7px);
    left: 0;
    width: 100%;
    height: multiply-size(21px);
    border-radius: 0 0 multiply-size(14px) multiply-size(14px);
    border-top-style: none;
    box-shadow: 0 multiply-size(-2px) 0 0 $box-shadow-color;
  }
}

//  LEFT OVERLAP
@mixin left-overlap($colors) {
  $box-shadow-color: map-get($colors, $background);
  @include base-before($colors);

  &:before {
    top: 0;
    left: multiply-size(1px);
    width: multiply-size(21px);
    height: 100%;
    border-radius: multiply-size(14px) 0 0 multiply-size(14px);
    border-right-style: none;
    box-shadow: multiply-size(2px) 0 0 0 $box-shadow-color;
  }
}

@mixin generate-schema-dialog-color($colors) {
  $font-color: map-get($colors, $font);
  $box-shadow-color: map-get($colors, $box-shadow);
  $background-color: map-get($colors, $background);

  color: $font-color;
  box-shadow: 0 0 0 multiply-size(2px) $box-shadow-color;
  background: radial-gradient(at center center, rgba($background-color, 0.7) 0%, rgba($background-color, 1) 80%);

  &.no-transparent {
    background: $background-color;
  }

  //  PRIMARY BUTTON OVERLAPS
  .top-overlap {
    @include top-overlap($colors);
  }

  .right-overlap {
    @include right-overlap($colors);
  }

  .bottom-overlap {
    @include bottom-overlap($colors);
  }

  .left-overlap {
    @include left-overlap($colors);
  }

  //  END OVERLAPS

  // Back bar overlaps
  .back-bar {
    @include left-overlap($colors);
  }

  // Navigation overlaps
  .navigation-bar {
    &.prev {
      @include left-overlap($colors);
    }

    &.next {
      @include right-overlap($colors);
    }
  }

  // Bottom bar overlaps
  .bottom-bar {
    .bar-button {
      @include bottom-overlap($colors);
    }
  }
}
