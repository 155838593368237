@import "~src/styles/variables/core/variables";
@import "../../core/variables";

$colors: (
  $font: $color-K,
  $background: $color-X,
  $box-shadow: $color-K,
);

&.primary {
  @include generate-schema-dialog-color($colors);
}

&.window-a {
  &.primary {
    box-shadow: none;

    &, &.no-transparent {
      background: var(--asset--ui-window-svg);
      background-size: contain;
    }
  }
}
