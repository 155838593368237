button {
  &.exit {
    border: none;

    &.small {
      width: multiply-size(26px);
      height: multiply-size(26px);
    }

    &.normal {
      width: multiply-size(32px);
      height: multiply-size(32px);
    }

    i {
      font-size: multiply-size(16px);
    }
  }
}
