@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";

.background-img {
  background: var(--asset--ui-backgroud_log-png);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: #FFFFFF;
    padding: 30px;
    border: 3px solid #000000;
    font-size: 16px;
    @include archivo-narrow;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 420px;
    height: auto;
    box-sizing: border-box;

    .description {
      font-size: 16px;
      font-weight: initial;
    }

    .text-error {
      font-size: 16px;
    }
  }
}
