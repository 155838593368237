@import "~src/styles/functions";
@import "~src/styles/variables/core/variables";
@import "~src/styles/mixins";

app-splash-screen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  pointer-events: none;

  .splash-bg {
    width: 100%;
    height: 100%;
    background: #ffffff var(--asset--ui-logo-png) center no-repeat;
    opacity: 0;
    transform: scale(1.5);
    transition: 0.3s;
  }

  &.active {
    pointer-events: all;
    background: #ffffff;

    .splash-bg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .percentage {
    font-size: multiply-size(30px);
    color: $color-base;
    @include futura-heavy;
    position: absolute;
    @include absolute-center-horizontal;
    bottom: multiply-size(40px);
  }

  loading {
    .loading-content {
      .relative {
        i {
          position: absolute !important;
          bottom: multiply-size(90px);
        }
      }
    }
  }

  @import "custom/style";
}
