@import "~src/styles/variables/core/variables";
@import "~src/styles/functions";
@import "~src/styles/mixins";

.global-dialog {
  width: multiply-size(450px);
  height: multiply-size(275px);

  border: multiply-size(2px) solid #000000;
  box-sizing: border-box;
  background: #ffffff;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    height: multiply-size(4px);
    background: $color-X;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  .timer-container {
    border: multiply-size(2px) solid #000000;
    background: $color-X;
    padding: multiply-size(1px) multiply-size(5px) multiply-size(1px) multiply-size(24px);
    min-width: multiply-size(30px);
    text-align: center;
    @include absolute-center-horizontal;
    transform: translate(-50%, -50%);
    top: 0;
    @include mikado;
    font-size: multiply-size(10px);
    color: #000000;
    z-index: 2;

    .icon {
      width: multiply-size(25px);
      height: multiply-size(25px);
      background-size: contain;
      position: absolute;
      @include absolute-center-vertical;
      left: multiply-size(-7px);
      background-image: var(--asset--icons-hud-time-png);
    }
  }

  .bottom-button {
    @include absolute-center-horizontal;
    bottom: 0;
    transform: translate(-50%, 50%);
    z-index: 2;
    width: auto;
    padding-left: multiply-size(15px);
    padding-right: multiply-size(15px);

    &.disabled {
      opacity: 1 !important;
    }
  }
}
