@import "~src/styles/variables/core/variables";
@import "~src/styles/functions";
@import "~src/styles/mixins";

app-quiz-question {
  @import "../styles/quiz-window";

  .global-dialog {
    padding: multiply-size(16px) multiply-size(30px);
  }

  .content {
    @include archivo-narrow;
    font-size: multiply-size(8px);
  }

  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: multiply-size(12px);
    margin-bottom: multiply-size(10px);

    .slider {
      --slider-width: 227px;
      --slider-height: 127px;

      width: multiply-size(var(--slider-width));
      min-width: multiply-size(var(--slider-width));
      height: multiply-size(var(--slider-height));
      min-height: multiply-size(var(--slider-height));
      position: relative;

      .youtube-container {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .controls {
        @include absolute-center-vertical;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 2;

        .control {
          width: multiply-size(13px);
          height: multiply-size(13px);
          opacity: 0.3;
          color: #ffffff;
          font-size: multiply-size(9px);
          background: #000000;
          @include text-stroke(transparent);
          border-radius: 50%;

          &:hover {
            opacity: 0.5;
          }

          &.prev {
            margin-left: multiply-size(6px);
            padding-right: multiply-size(2px);
          }

          &.right {
            margin-right: multiply-size(6px);
            padding-left: multiply-size(2px);
          }
        }
      }

      img {
        top: 0;
        left: 0;
        position: absolute;
        object-fit: contain;
        width: 100%;
        height: 100%;
        z-index: 0;

        &.active {
          z-index: 1;
        }
      }
    }
  }
  perfect-scrollbar{
    height: multiply-size(108px);
  }

  .answers {
    display: flex;
    flex-direction: column;
    row-gap: multiply-size(10px);
    margin-top: multiply-size(-10px);
    margin-bottom: multiply-size(5px);

    .answer {
      display: flex;
      align-items: center;

      .answer-char {
        @include mikado;
        font-size: multiply-size(10px);
        font-weight: bold;
        width: multiply-size(8px);
        min-width: multiply-size(8px);
      }

      button {
        &.checkbox {
          min-width: multiply-size(16px);
          width: multiply-size(16px);
          min-height: multiply-size(16px);
          height: multiply-size(16px);
          margin: 0 multiply-size(6px);
          border-radius: multiply-size(3px);
          border: multiply-size(1px) solid #000000 !important;
          box-shadow: none;
        }

        i {
          font-size: multiply-size(6px);

          &.fa-circle {
            color: #000000;
            text-shadow: none;
          }
        }
      }
    }
  }
}
