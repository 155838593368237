app-ranking-details-game {
  @import "../../styles/styles";

  .my-position-container {
    label {
      @include archivo-narrow();
      font-size: multiply-size(7px);
    }
  }

  .padding-bottom {
    padding-bottom: multiply-size(21px) !important;
  }
}
